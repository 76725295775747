.noprice {
    font-size: 1rem;
    margin-bottom: 0;
}

.line {
    text-decoration: line-through;
    font-size: 1rem;
    margin-bottom: 0;
}

.newprice {
    font-size: 1.4rem;
    color: var(--primary-color) !important;
}
.info {
    margin-left: 20px;
    font-size: 20px !important;
    color: black !important;
}

.discountBadge {
    display: none;
}

.active:hover {
    .discountBadge {
        position: absolute;
        top: -65px;
        left: 20px;
        background-color: var(--primary-color);
        color: var(--croonus-white);
        padding: 0.25rem 0.5rem;
        border-radius: 5px;
        font-size: 0.75rem;
        font-weight: 500;
        transition: all;
        display: block;
        transition-duration: 500ms;
        width: max-content;
        text-align: left;
    }
}

.form {
    display: flex !important;
    .addToCart {
        margin-left: 1rem;
        min-width: 200px;
    }
}

.info:hover .discountBadge {
    position: absolute;
    top: -0px;
    left: 0px;
    background-color: var(--primary-color);
    color: var(--croonus-white);
    padding: 0.25rem 0.5rem;
    border-radius: 5px;
    font-size: 0.75rem;
    font-weight: 500;
    transition: all;
    display: block;
    transition-duration: 500ms;
    width: max-content;
    text-align: left;
    z-index: 10000;
}
.newprice:hover .discountBadge {
    position: absolute;
    top: -65px;
    left: 20px;
    background-color: var(--primary-color);
    color: var(--croonus-white);
    padding: 0.25rem 0.5rem;
    border-radius: 5px;
    font-size: 0.75rem;
    font-weight: 500;
    transition: all;
    display: block;
    transition-duration: 500ms;
    width: max-content;
    text-align: left;
}
.wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    .cart {
        width: 60%;
        display: flex;
        align-items: center;
        gap: 1em;
        min-width: 8rem;
    }

    .price {
        width: 40%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 12rem;

        .inactive {
            text-decoration: line-through;
            font-size: 0.9em;
        }

        .active {
            font-size: 1.2em;
            font-weight: 600;

            em {
                font-size: 0.7em;
                line-height: 2em;
                font-weight: 400;
                font-style: normal;
                padding-left: 0.5em;
            }
        }
    }
}

.wishlist {
    background-color: transparent;
    padding: 0 var(--gap-half) !important;
    height: 40px;
    min-width: 2rem;
    transition: all 0.5s;
}

.heart {
    height: 30px;
    transition: all 0.5s;
}

.wishlist:hover {
    .heart {
        filter: invert(1);
    }
}

.inWishlist {
    background-color: var(--primary-color);

    .heart {
        filter: invert(1);
    }
}

.addToCart {
    padding: 0 var(--gap-half) !important;
    font-size: 1rem;

    span {
        margin-right: var(--gap);
    }
}

.input {
    max-width: 5rem !important;
}

.tcPrice {
    text-decoration: none;
    color: var(--croonus-black);
}

.discounted {
    color: var(--primary-color);
    text-decoration: none;
}

.discountedUnderline {
    text-decoration: dotted;
    text-underline-position: under;
    text-decoration-line: underline;
}

.rebateStyle {
    color: var(--croonus-black);
    text-decoration: dotted;
    text-underline-position: under;
    text-decoration-line: underline;
}

.discountRabate {
    color: var(--primary-color);
    text-decoration: dotted;
    text-underline-position: under;
    text-decoration-line: underline;
}

@media screen and (max-width: 768px) {
    .wrapper {
        flex-direction: column;
    }
    .cart {
        width: 100% !important;
        padding: 0;
    }
    .addToCart {
        flex-direction: row;
        justify-content: center;
        padding: 0.6rem !important;
    }

    .price {
        width: 100% !important;
        border-top: 1px solid var(--border-color);
        border-bottom: 1px solid var(--border-color);
        padding: var(--gap);
        margin-top: 2rem;
        margin-bottom: 2rem;

        .active {
            font-weight: 800 !important;
        }

        em {
            padding-left: 0 !important;
        }
    }
}
