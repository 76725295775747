.product {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: var(--gap-half);
    margin-top: 1.5rem;

    justify-content: space-between;
}

.productImage {
    width: 20%;

    img {
        width: 100%;
    }
}

.productData {
    width: 100%;
}

.productName {
    margin: 0;
    margin-bottom: var(--gap-half);
    font-size: 0.9rem;
    max-height: 1.2rem;
    font-weight: 700;
}

.productSku {
    font-size: 0.8rem;
}

.productDataPrice {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    margin-top: 0.5rem;
}

.productPrice {
    font-size: 0.8rem;

    margin-left: var(--gap-half);
}

.productQuantity {
    width: 100%;
    height: 2rem;
    min-width: 0 !important;
    //grid-template-columns: 1rem 2rem 1rem;

    button,
    input {
        padding: 0;
        justify-content: center;
        font-size: 0.8rem !important;
    }
}

.productRemove {
    width: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
}

.tcPrice {
    text-decoration: none;
}

.discounted {
    color: var(--primary-color);
    text-decoration: none;
}

.rebateStyle {
    text-decoration: dotted;
    text-underline-position: under;
    text-decoration-line: underline;
}

.discountRabate {
    text-decoration: dotted;
    text-underline-position: under;
    text-decoration-line: underline;
    color: var(--primary-color);
}
