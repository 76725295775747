.notificationTitle {
    width: 100%;
    display: block;
    border-top: 1px solid var(--croonus-lightGray);
    height: 3rem;
    display: flex;
    align-items: center;
    padding: 0.5rem;
    text-decoration: none;
    position: relative;
    overflow: hidden;
}

.notificationTitleNew {
    background-color: var(--croonus-lightGray);
}

.new {
    position: absolute;
    top: 0.2rem;
    right: 1rem;
    background-color: var(--primary-color);
    padding: 0.5rem 2rem;
    transform: rotate(45deg);
    text-transform: uppercase;
    font-weight: bold;
    color: var(--croonus-lightGray);
}

.notificationBody {
    padding: var(--gap);
}

.time {
    font-size: 13px;
    color: var(--croonus-inactive);
    margin-bottom: var(--gap);
}

.message {
    margin-bottom: var(--gap);
    font-size: 1rem;
}

.button {
    display: block;
    color: var(--croonus-white);
    background-color: var(--primary-color);
    text-decoration: none;
    padding: var(--gap-half);
    border-radius: 10px;
    width: max-content;
}
