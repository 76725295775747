.button {
    padding: 0.3rem 0;
    width: 100%;
    text-align: center;
    height: 40px;
    min-width: fit-content;
    img {
        height: 22px;
        filter: brightness(0) invert(1);
        margin-left: 0.4em;
    }
}

@media only screen and (max-width: 768px) {
    .button {
        width: 100%;
        padding: 0.3rem 0.5rem;
        display: flex;
        flex-direction: column-reverse;
        height: 100%;
        align-items: center;
        img {
            margin: 0;
        }
    }
}
.loading2 {
    visibility: hidden;
}
.wrapper.nopadding {
    padding: 0;
}

.loading {
    position: absolute;
    left: 50%;
    top: 43%;
    transform: translate(-50%, -50%);
}
