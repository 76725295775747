.show {
	display: block;
}

.hide {
	display: none;
}

.flex {
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-top: 3rem;
}

.flex1 {
	display: flex;
	flex-direction: column;
	gap: 0.3rem;
	align-items: start;
}

.flexLabel {
	font-weight: 600;
}

.variants {
	font-size: 1.25rem;
	display: flex;
	flex-direction: row;
	background-color: white;
	border-radius: 5px;
	height: 33px;
	align-items: center;
	width: 100%;
	border: none;
	font-weight: 400;
	padding: 0 0.5rem;
	cursor: pointer;
	outline: 1px solid var(--border-color);
}
