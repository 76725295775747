.searchInput {
    border: 1px solid var(--croonus-lightGray);
    padding: 0.2rem 1rem;
    width: 100%;
}

.searchButton {
    height: 100%;
    background-color: var(--primary-color);
    padding: 0 1rem;
    border: none;
    img {
        height: 60%;
    }
}

.search {
    height: 2.5rem;
    display: flex;
    padding: 0 0.5rem;
    margin-bottom: 2rem;
}
