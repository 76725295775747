.button {
    all: unset;
    text-decoration: none;
    display: inline-block;
    color: var(--croonus-white);
    background-color: var(--primary-color);
    width: fit-content;
    padding: 0.4rem 1rem;
    position: relative;
    border-radius: 5px;
    font-weight: 450;
    cursor: pointer;

    &:hover {
        color: var(--croonus-white);
        background-color: var(--primary-color-hover);
    }
}

.outline {
    background-color: white;
    color: var(--primary-color);
    outline: 2px solid var(--primary-color);

    &:hover {
        background-color: white;
        color: var(--primary-color-hover);
        outline: 2px solid var(--primary-color-hover);
    }
}

.wrapper.nopadding {
    padding: 0;
}

.loading {
    position: absolute;
    left: 50%;
    top: 43%;
    transform: translate(-50%, -50%);
}

.loading2 {
    visibility: hidden;
}
