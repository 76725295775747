.container {
    padding: 1rem 3rem;
    width: 100%;
}
.heading {
    font-weight: 600;
    margin-bottom: 2rem;
}
.sub-heading {
    margin-top: 2rem;
    font-weight: 600;
}
.help-list {
    padding-left: 1rem;
    line-height: 2rem;
    font-size: 1rem;
}
.shopping-manual {
    color: var(--croonus-black);
    text-decoration: underline;
    &:hover {
        color: var(--primary-color);
    }
}

@media screen and (max-width: 768px) {
    .container {
        padding: 1rem;
    }
}
