.wrapper {
    width: 100%;
    height: 60px;
    padding: 0 2.4rem;
    font-weight: 600;
    font-size: 1.2rem;
    color: var(--croonus-white);
    background-color: var(--primary-color);
    margin: 0 0 0.4rem 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    .title {
        margin-bottom: 0;
        font-weight: 600;
    }
    .imgHolder {
        height: 50%;
        img {
            display: block;
            height: 100%;
            filter: brightness(0) invert(1);
        }
    }
    &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 0.4rem;
        background-color: var(--primary-color);
        opacity: 0.3;
        bottom: -0.4rem;
        left: 0;
    }
}

// TODO Style
.action {
    color: var(--croonus-white);
    font-size: 1.2em;
    float: right;
    cursor: pointer;
}
