.wrapper {
    > a {
        text-decoration: none;
        padding-left: var(--gap-half);
        color: var(--croonus-black);

        &:after {
            content: "/";
            margin-left: var(--gap-half);
            color: var(--croonus-black);
            opacity: 0.29;
        }

        &:hover {
            color: var(--primary-color);
        }

        &:first-child {
            padding-left: 0;
        }
    }

    > span {
        padding: 0 var(--gap-half);
    }
}

.crumbProduct {
    font-weight: 500;
}
