.table {
    width: 100%;
    margin-top: 1rem;
    overflow: auto;
    border-top: none;

    > thead {
        > tr {
            background-color: var(--secondary-color);
            color: var(--croonus-white);
            width: 100%;
            border-radius: 0.5rem;
            padding: 0.4rem 2rem;
            justify-content: space-around;
            font-weight: 500;

            // > th:not(:last-child)::before {
            //     content: "";
            //     height: 15px;
            //     width: 1px;
            //     background-color: white;

            //     display: flex;
            //     top: 0.8rem;
            //     left: 0rem;
            //     position: absolute;
            // }
            > th::before {
                content: "";
                height: 15px;
                width: 1px;
                background-color: white;

                display: flex;
                top: 50%;
                transform: translateY(-50%);
                left: 0rem;
                position: absolute;
            }
            > th:first-child::before {
                content: none;
            }

            > th {
                position: relative;
                font-weight: 500;
            }
            > th:first-child {
                border-top-left-radius: 0.5rem;
                border-bottom-left-radius: 0.5rem;
            }
            > th:last-child {
                border-top-right-radius: 0.5rem;
                border-bottom-right-radius: 0.5rem;
            }
        }
    }

    > tbody {
        display: block;
        width: 100%;
        overflow: auto;
        table-layout: fixed;

        > tr {
            display: table;
            line-height: 1rem;
            // Zebra colors, with hover
            background-color: var(--table-row-odd);
            &:hover {
                cursor: pointer;
            }
            &:nth-child(even) {
                background-color: #f8f8fa;
            }
        }
    }

    thead,
    tbody tr,
    tr {
        display: table;
        width: 100%;
        table-layout: fixed;
    }
    thead {
        width: calc(100%);
    }
    th {
        text-align: center;
        font-weight: 600;
    }
}

// Applies to both headers and cells
.table > * > * > * {
    text-align: center;
    padding: var(--gap-half) var(--gap);

    // Text alignment
    &:global.left {
        text-align: left !important;
    }
    &:global.center {
        text-align: center !important;
    }
    &:global.right {
        text-align: right !important;
    }

    @media only screen and (max-width: 768px) {
        &:global.mobile-hidden {
            display: none;
        }
    }
}

.tcImage {
    width: 100px;

    img {
        max-width: 80%;
        aspect-ratio: 230/150;
    }
}

.tcTitle {
    text-align: left !important;
}

.tcPrice {
    text-align: right !important;
    width: 160px;
}
.tcTotalLabel {
    font-weight: 800;
    text-align: right !important;
    padding-top: 1.5em;
}
.tcTotalPrice {
    font-weight: 800;
    padding-top: 1.5em;
    text-align: right !important;
}

.tcInt {
    width: 100px;
}

.tcActions {
    width: 150px;
    display: flex;

    > i {
        cursor: pointer;
        padding: 10px 13px;
        margin: 3px 0 3px 2px;
        transition: color 240ms;
        color: var(--croonus-inactive);

        &:hover {
            color: var(--croonus-alertRed);
        }
    }
}

@media print {
    .table > * > * > * {
        font-size: 0.8rem;
        border-bottom: 1px solid var(--croonus-lightGray);
        // &:first-child {
        //     width: 200px;
        // }
        // text-align: left;
        // &:global.center {
        //     display: none !important;
        // }
    }
    .table > thead {
        border-bottom: 1px solid var(--croonus-lightGray);
    }
    .table > thead > tr > th {
        font-size: 0.8rem;
        // &:first-child {
        //     width: 200px;
        // }
    }
}
