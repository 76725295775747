.rs-slider-bar {
	background-color: var(--primary-color);
	border-radius: 3px;
	cursor: pointer;
	height: 5px;
}

.rs-slider-progress-bar {
	left: 0%;
	width: 100%;
	background-color: black;
	border-radius: 3px 0 0 3px;
	height: 5px;
	position: absolute;
}

.rs-slider-handle {
	left: 0%;
	outline: none;
	position: absolute;
	top: -50%;
}

.rs-slider-handle:before {
	background-color: #fff;
	border: 1px solid #c41e3a;
	border-radius: 50%;
	content: "";
	cursor: pointer;
	height: 12px;
	top: -2px;
	margin-left: -6px;
	position: absolute;
	transition: box-shadow 0.3s ease-in-out, background-color 0.3s ease-in-out,
	transform 0.3s ease-in-out;
	width: 12px;
}

.rs-slider-progress-bar {
	background-color: var(--primary-color);
	border-radius: 3px 0 0 3px;
	height: 6px;
	position: absolute;
}

.rs-tooltip-arrow {
	display: none;
}
