.buttons {
	display:         flex;
	justify-content: center;
	gap:             var(--gap);
	margin-top:      var(--gap);
}

.left {
	justify-content: start;
}

.center {
	justify-content: center;
}

.right {
	justify-content: end;
}
