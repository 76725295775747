.pageWrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    height: calc(100vh - 60px);
    height: calc(var(--vh, 1vh) * 100 - 60px);
    margin-top: 60px;
    overflow: hidden;
}

.logedOutWrapepr {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    width: 100%;
}

@media screen and (max-width: 768px) {
    .pageWrapper {
        flex-direction: column-reverse;
        height: calc(var(--vh, 1vh) * 100 - 120px);
    }
    .logedOutWrapepr {
        flex-direction: column-reverse;
    }
}

@media print {
    .pageWrapper {
        height: 100%;
    }
}
