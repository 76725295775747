.link:hover > a {
    text-decoration: underline !important;
}

.promo {
    span {
        color: var(--primary-color);
    }
    font-weight: 600;
    padding: var(--gap-quarter) var(--gap);
}

.wrapper {
    display: flex;
    flex-direction: column;
    width: 20rem;
    position: fixed;
    height: calc(100% - 60px);
    background-color: var(--croonus-lightGray);
    z-index: 100;

    @media (max-width: 768px) {
        & {
            display: none;
        }
    }
}

.list {
    width: 100%;
    background-color: var(--croonus-lightGray);
    overflow-y: auto;
    overflow-x: hidden;

    & .superLink {
        display: flex;
        text-decoration: none;
        gap: var(--gap);
        color: var(--croonus-black);
        padding: var(--gap-half) var(--gap);
        border: unset;
        font-size: 0.9rem;
        font-weight: 500;
        width: 100%;
        text-align: left;
        background-color: var(--croonus-lightGray);
        position: relative;
    }

    & .superLink:hover {
        color: var(--primary-color);
        background-color: var(--croonus-white);
    }

    &::-webkit-scrollbar {
        display: block;
        width: 3px;
        max-height: 50%;
        height: 50%;
    }

    &::-webkit-scrollbar-track {
        max-height: 50%;
        height: 50%;
    }

    &::-webkit-scrollbar-thumb {
        background-color: var(--croonus-scrollColor);
    }

    &::-webkit-scrollbar-track-piece:start {
        background: transparent;
    }
}

.activeCategory {
    display: flex;
    align-items: center;
    padding: var(--gap);
    background-color: var(--primary-color);
    cursor: pointer;
    color: var(--croonus-white);
    gap: var(--gap);
    font-size: 0.9em;

    strong {
        font-size: 1.3em;
        font-weight: 700;
    }
}

.label {
    padding: var(--gap-half) var(--gap);
    background-color: var(--primary-color);
    font-weight: 600;
    color: var(--croonus-white);
    width: 100%;
    //margin-bottom: var(--gap-half);
}

.active > .superLink {
    color: var(--primary-color);
    background-color: var(--croonus-white);
    font-weight: 600;
}

.items {
    overflow: auto;
    scrollbar-width: thin;
}

.items::-webkit-scrollbar {
    width: 5px;
}

.items::-webkit-scrollbar-track {
    background-color: transparent;
}

// TODO
.icon {
    width: 20px;
    height: 20px;
}
