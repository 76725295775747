.wrapper {
    display: flex;
    font-size: 0.9rem;
    gap: 3rem;
    max-width: 34.5rem;
    flex-wrap:wrap;
    margin-bottom: var(--gap);
}
.data {
    display: flex;
}
.dataTitle {
    font-weight: 600;
    margin-right: var(--gap-half);
}
.dataValue {
    font-weight: 400;
}

.description {
    h6 {
        font-weight: 600;
    }
}

.container {
    width: 90%;
}

.dataTitleImage {
    display: inline;
    margin-right: var(--gap-half);
    img {
        display: block;
        width: 1.2rem;
    }
}

@media screen and (max-width: 768px) {
    .wrapper {
        margin-bottom: 0.5rem;
        padding: 0;
    }
    .status {
        .dataTitle {
            display: none;
        }
    }
}
