.wrapper {
	position:         relative;
	width:            100%;
	height:           auto;
	background-color: var(--croonus-darkGray);
	padding:          var(--gap);
	color:            var(--croonus-lightGray);
	text-align:       left;

	a {
		display:       block;
		color:         var(--croonus-white);
		font-weight:   600;
		margin-bottom: 0.25rem;
	}

	p {
		margin:      0;
		font-weight: 400;
		font-size:   0.9rem;
		line-height: 17px;
	}

}
.companyName {
	overflow:           hidden;
	text-overflow:      ellipsis;
	display:            -webkit-box;
	-webkit-line-clamp: 1;
	line-clamp:         1;
	min-height:         25px;
	-webkit-box-orient: vertical;
}

.redText {
	color: var(--croonus-lightRed);
}

.greenText {
	color: var(--croonus-lightGreen);
}
