.unread {
    font-weight: 700;
}

.unreadIcon {
    float: right;
    position: absolute;
    right: 0.5rem;
    top: 1.2rem;
}
