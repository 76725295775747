.wrapper {
    width: 100%;
    position: relative;

    > * {
        padding: 1rem;
        cursor: pointer;
        position: relative;

        &:hover {
            background-color: var(--croonus-lightGrayHover);
        }
    }

    > *::before {
        content: "";
        width: 75%;
        position: absolute;
        bottom: 0;
        left: 30%;
        height: 0.8px;
        background-image: linear-gradient(to right, rgba(219, 219, 219, 0), rgb(193 193 193 / 51%));
    }
}

.active {
    font-weight: 600;
}
