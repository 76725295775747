.wrapper {
    height: 100%;
    background-color: var(--secondary-color);
    width: 70px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.links {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: auto;

    &::-webkit-scrollbar {
        width: 0.25rem;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #ffffff;
        outline: 1px solid var(--secondary-color);
        border-radius: 1px;
    }
}

.link {
    position: relative;
    text-decoration: none;
    display: flex;
    width: 40px;
    padding: 0.4em;
    border-radius: 5px;
    margin-bottom: 1rem;
    color: var(--croonus-white);
    text-decoration: none;
    font-size: 0.8rem;
    img {
        width: 100%;
        filter: brightness(0) invert(1);
    }
    span {
        margin: 0 var(--gap-half);
    }
}

.link:hover {
    color: var(--croonus-white);
    background-color: var(--primary-color);
}

.active {
    background-color: var(--primary-color);
}

.badge {
    position: absolute;
    top: 0;
    right: 0;
    font-style: normal;
    border-radius: 100%;
    background-color: var(--croonus-darkRed);
    color: var(--croonus-white);
    width: 20px;
    height: 20px;
    display: flex;
    font-size: 0.8rem;
    font-weight: 600;
    justify-content: center;
}

.navMobile {
    display: none;
}

@media screen and (max-width: 768px) {
    .wrapper {
        height: 60px;
        width: 100%;
        flex-direction: row;
        background-color: var(--croonus-lightGray);
        padding: 0 0.75rem;
    }
    .links {
        flex-direction: row;
    }
    .link {
        background-color: var(--primary-color);
        margin-left: 0.2rem;
        width: auto;
        height: 40px;
        align-items: center;
        margin-bottom: 0;
        img {
            height: 90%;
            width: auto;
        }
    }

    .active {
        background-color: var(--croonus-black) !important;
        color: var(--croonus-white) !important;
    }
    .navMobile {
        z-index: 3;
        display: flex;
        position: fixed;
        align-items: center;
        bottom: 0;
    }
    .navDesktop {
        display: none;
    }
}

@media print {
    .wrapper {
        display: none;
    }
}
