.debt {
    display: flex;
    align-items: center;
    background: var(--croonus-lightGray);
    padding: 2rem;
    padding-top: 2.5rem;
    justify-content: center;
    gap: 1rem;
    border-radius: 1rem;
    margin-top: 2rem;
    margin-bottom: 4rem;
}

.debtSection {
    width: 50%;
    font-weight: 500;
    text-align: center;
}

.debtTitle {
    font-size: 4rem;

    margin: 0;
    padding: 0;
}

.debtSubtitle {
    font-size: 1.2rem;
}

.debtDescription {
    font-size: 1.2rem;
}

.subtitle {
    margin-top: 2rem;
}

.question {
    text-align: center;
    font-size: 1.2rem;
    margin-bottom: 3rem;
}

.questionTitle {
    font-weight: 600;
    margin: 0;
}

.divider {
    width: 3px;
    height: 8rem;
    background: var(--primary-color);
}

@media screen and (max-width: 768px) {
    .debt {
        flex-direction: column;
    }

    .divider {
        width: 50%;
        height: 3px;
    }

    .debtSection {
        width: 100%;
    }
    .debtTitle {
        font-size: 3rem;
    }
}
