.analyticsContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    row-gap: 2rem;
}

.ananlyticBlock {
    width: calc(25% - 5rem);
    display: flex;
    min-width: 290px;
    border-radius: 10px;
    color: white;
}

.ananlyticBlockIcon {
    width: 30%;
    border-radius: 10px;
    img {
        margin: 25%;
        width: 50%;
        filter: brightness(1) invert(1);
    }
}

.ananlyticBlockData {
    width: 70%;
}
.ananlyticBlockTitle {
    width: 80%;
    margin: 0;
    padding: 0;
    margin-left: 20%;
    border-bottom: 2px solid white;
    text-align: right;
    padding: 2% 10% 2% 0;
}

.ananlyticBlockValue {
    width: 100%;
    width: 80%;
    margin: 0;
    margin-left: 20%;
    text-align: right;
    padding: 10%;
    padding-left: 0;
    font-size: 1.3rem;
    font-weight: bold;
}
.red {
    background-color: #dd4c39;
    .ananlyticBlockIcon {
        background-color: #b13c2e;
    }
}

.blue {
    background-color: #0572b7;
    .ananlyticBlockIcon {
        background-color: #015c92;
    }
}

.green {
    background-color: #07a55a;
    .ananlyticBlockIcon {
        background-color: #048548;
    }
}

.yellow {
    background-color: #ffc906;
    .ananlyticBlockIcon {
        background-color: #bb960d;
    }
}
