.actionField {
    display: flex;
    justify-content: flex-end;
    gap: var(--gap-half);
}

.actionIcon {
    color: var(--secondary-color);
}

.actionIcon:hover {
    color: var(--primary-color);
}
