.wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    aspect-ratio: 3.5/1;
    width: 100%;
    overflow: hidden;
    margin-bottom: 1rem;

    > div {
        position: relative;
        overflow: hidden;

        > div {
            position: absolute;

            img {
                border-radius: 5px;
                width: 100%;
            }
        }
    }
}

.primary {
    position: relative;
    width: 66%;
    img {
        aspect-ratio: 2.4/1;
    }
}

.secondary {
    position: relative;
    width: 33%;
    img {
        aspect-ratio: 1.2/1;
    }
}

.banner {
    display: block;
    z-index: 0;
    animation: fadeOut 0.8s forwards;
}

.banner-active {
    z-index: 1;
    animation: fadeIn 0.8s forwards;
    width: 100%;
}

.button {
    color: red;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.image {
    width: 100%;
    object-fit: fill;
}

.texts {
    top: 0.75rem;
    left: 0.75rem;
    position: absolute;
    padding: 1rem;
    font-weight: bold;
    background: #ffffff94;
    border-radius: 0.5rem;
    min-width: 30%;
    h3 {
        font-size: 24px;
        margin-bottom: 0.6rem;
    }
    h4 {
        font-size: 18px;
        margin-bottom: 0.2rem;
    }
    p {
        font-size: 14px;
        margin-bottom: 0px;
    }
}

.mobileWrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    overflow: hidden;
    margin-bottom: 1rem;
}

.dots {
    position: absolute;
    bottom: 2rem;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    gap: 0.5rem;

    z-index: 1;
}

.dot {
    width: 0.7rem;
    height: 0.7rem;
    position: relative;
    z-index: 1;
    border-radius: 50%;
    background-color: var(--croonus-lightGray);
    display: block;
}
.dotActive {
    background-color: var(--croonus-darkGray);
}
