.linkList {
    width: 100%;
}

.link {
    width: 100%;
    display: block;
    height: 3rem;
    display: flex;
    align-items: center;
    padding: 0.5rem;
    text-decoration: none;
    position: relative;
}

.link::before {
    content: "";
    width: 75%;
    position: absolute;
    bottom: 0;
    right: 0;
    height: 1px;
    background-image: linear-gradient(to right, rgba(193, 193, 193, 0), rgb(193, 193, 193));
}

.linkChildren {
    padding-left: 1rem;
}

.active {
    .linkLabel {
        color: var(--primary-color);
        font-weight: 600;
    }
}

.linkIcon {
    height: 100%;
    width: 2rem;
    img {
        width: 2rem;
        display: block;
        margin: 0;
        filter: brightness(0);
    }
}

.linkLabel {
    text-decoration: none;
    text-transform: uppercase;
    padding: 0 1rem;
    color: var(--croonus-black);
}
