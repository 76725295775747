.chatBox {
    display: flex;
    margin-bottom: 1rem;
    padding: 0.5rem;
    background-color: var(--croonus-lightGray);
    word-break: break-word;
    border-radius: 0.625rem;
    flex-direction: column;
    min-height: auto;
    // margin-left: 0.5rem;
}

.createdAt {
    font-size: 0.6rem;
}
