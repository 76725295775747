.noitem {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    margin-top: 10rem;
    margin-bottom: 10rem;
}

.wrapper {
    padding: 1rem 1rem 1rem 21rem;
    width: 100%;

    @media (max-width: 768px) {
        & {
            padding: 1rem;
        }
    }
}

.productWrapper {
    margin: var(--gap) 0;
    display: flex;
    width: 100%;
}

.gallery {
    display: flex;
    flex-direction: row;
    padding-right: var(--gap);
    width: 55%;
    position: relative;
}

.price {
    float: left;
}

.title {
    font-weight: 600;
    max-height: 3rem;
    overflow: hidden;
    text-overflow: ellipsis;
}

.main-details-image-galery {
    display: flex;
    flex-direction: column;
    margin-right: 1rem;
}

.main-details-image-galery-item {
    display: flex;
    align-items: center;
    height: 100px;
    img {
        width: 100%;
    }
    img:hover {
        box-shadow: 0 0 2px 0.5px var(--border-color);
    }
}
.main-details-primary-image {
    display: flex;
    align-items: center;
    height: 300px;
    img {
        width: 100%;
    }
}
.main-details-under-primary-image {
    height: 100px;
    display: flex;
    align-items: center;
}
.main-details-under-primary-image-button {
    object-fit: contain;
    border: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: transparent;
    span {
        font-size: 0.8rem;
        white-space: nowrap;
    }
    img {
        width: 599px;
    }
}
.main-details-under-primary-image-button:hover {
    box-shadow: 0 10px 5px -10px var(--border-color);
}
.hero {
    border-radius: 20px;
    background-color: var(--croonus-lightGray);
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 45%;
    position: relative;
    min-height: 400px;

    input {
        font-size: 0.85rem;
        font-weight: 500;
    }
    select {
        font-size: 0.85rem;
        font-weight: 500;
    }
    button {
        font-size: 0.85rem;
        padding: 0.375rem 1rem;
        font-weight: 600;
    }
}

.form-select {
    width: 200px;
}

.details {
    margin-top: 3rem;
}

.cart-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    height: 36px;
}
.cart-counter-middle {
    margin-left: 1rem;
}
.cart-button-img {
    height: 30px;
    filter: brightness(0) invert(1);
}
.hero-sizeselect {
    margin-top: 2rem;
    display: flex;
    align-items: flex-end;
    flex-direction: row;
}

.size-select {
    display: flex;
    height: 50%;
    flex-direction: row;
}

.breadCrumbs {
    display: flex;
    gap: var(--gap-half);
    margin-bottom: 2rem;
}

.crumb {
    color: var(--croonus-black);
    text-decoration: none;
}

.crumb:hover {
    color: var(--croonus-black);
}

.crumbProduct {
    font-weight: 500;
    cursor: pointer;
}

@media screen and (max-width: 1168px) {
    .wrapper {
        height: calc(100vh - 120px);
        height: calc(var(--vh, 1vh) * 100 - 120px);
    }
    .productWrapper {
        flex-direction: column;
    }

    .gallery {
        width: 100%;
        height: 400px;
    }

    .hero {
        width: 100%;
    }
}

@media screen and (max-width: 768px) {
    .title {
        font-size: 1.2rem;
    }

    .hero {
        background-color: transparent;
        padding: 0;
        min-height: unset;
        margin-bottom: 1rem;
    }
}
