.containered {
	padding-bottom: 1rem;
}
.promolistwrapper {
	width: 100%;
	display: flex;
	flex-direction: row;
	gap: 2rem;
	flex-wrap: wrap;
}

.promolist {
	width: 100%;
	@media (786px <= width <= 1920px) {
		width: 50%;
		padding: 0rem;
	}
	@media (width <= 768px) {
		width: 100%;
		padding: 1rem;
	}
}

.promolistitemcontenttitle {
	font-size: 20px;
	font-weight: 600;
}

.promolistitem {
	padding: 0.75rem;
	border: 1px solid #e0e0e0;
	border-radius: 20px;
	text-decoration: none !important;
	color: black !important;
	@media (width <= 768px) {
		width: 100%;
	}
}

.promolistitemimg {
	@media (width <= 768px) {
		width: 100%;
	}
	& img {
		@media (width <= 768px) {
			width: 100%;
		}
		object-fit: cover;
	}
}

.title {
	font-size: 1.5rem;
	font-weight: 600;
}
