:root {
    // Main theme
    /* --primary-color: #28a86e;
    --primary-color-hover: #22915f;
    --primary-hover: rgb(40, 168, 110, 0.215);
    --secondary-color: #1b1b1b;
    --border-color: rgba(30, 30, 30, 0.1);
    --croonus-alertRed: #f34f5d;
    --croonus-scrollColor: #cfcfcf;
    --text-color: #333333; */
    --primary-color: #42b84a;
    --primary-color-hover: #3ba742;
    --primary-hover: rgb(40, 168, 110, 0.215);
    --secondary-color: #1b1b1b;
    --border-color: rgba(30, 30, 30, 0.1);
    --croonus-alertRed: #f34f5d;
    --croonus-scrollColor: #cfcfcf;
    --text-color: #333333;

    // Common
    --croonus-black: #030303;
    --croonus-white: #ffffff;
    --croonus-white-darker: #f0f0f0;
    --croonus-lightGray: #f8f8fa;
    --croonus-lightGrayHover: #e5e5e5;
    --croonus-lightGrayBorder: #ffffff;
    --croonus-darkGray: #333333;
    --croonus-inactive: #b0b0b0;
    --croonus-darkBlue: #015c93;
    --croonus-lightBlue: #0073b6;
    --croonus-darkGreen: #008548;
    --croonus-lightGreen: #00a65a;
    --croonus-darkRed: #b13b2d;
    --croonus-lightRed: #dd4c39;
    --croonus-darkYellow: #bb950c;
    --croonus-lightYellow: #ffcb06;

    // Table
    --table-row-odd: var(--croonus-white);
    --table-row-even: var(--croonus-white-darker);
    --table-row-hover: var(--primary-hover);

    // Dimensions
    --gap: 1rem;
    --gap-half: 0.5rem;
    --gap-quarter: 0.25rem;
}

:export {
    primaryColor: var(--primary-color);
    red: var(--croonus-lightRed);
}
