.buttonInfoAboutRec {
    margin-top: 0.1rem;
    align-items: center;
}

.formInputInfoAboutRec {
    flex: auto;
    margin: 0.5rem 0 0.25rem 0;
    input {
        width: 100%;
        display: block;
        outline: none;
        border: none;
        font-size: 0.9rem;
        padding: 0.5rem 1rem;
        border-radius: 10px;
        background-color: var(--croonus-lightGray);
        box-shadow: unset;
    }
}

.buttonRemove {
    background-color: var(--croonus-lightRed);
    &:hover {
        background-color: var(--croonus-darkRed);
    }
}

.buttonRemove,
.buttonSearch {
    width: 100%;
    justify-content: center;
}

.wrapper {
    select,
    input,
    textarea {
        width: 100%;
        display: block;
        outline: none;
        border: none;
        font-size: 0.9rem;
        padding: 0.5rem 1rem;
        border-radius: 10px;
        background-color: var(--croonus-lightGray);
        box-shadow: unset;

        &[type="checkbox"] {
            padding: 0.5rem 1rem;
            margin-left: 0.5em;
        }
    }
    // }

    .wrapper:last-child {
        hr {
            display: none;
        }
    }

    .form-check {
        display: flex;
        align-items: center;
    }

    .form-check-input {
        border: none;
        height: 30px;
        margin-top: 0;
        background-color: var(--croonus-lightGray);
    }

    .form-check-input:focus {
        box-shadow: none;
    }

    .form-check-input:checked {
        background-color: var(--primary-color);
    }

    .error-text {
        display: block;
        color: red;
        font-size: 0.675rem;
        line-height: 0.675rem;
        height: 0.675rem;
        margin: 0.25rem 0;
    }

    .no-error-text .error-text {
        display: none;
    }
}
