.formsContainer {
    display: flex;
    align-items: flex-start;
}

.form {
    width: 50%;
    margin: 0;
}

.formDivider {
    display: block;
    width: 3px;
    border-radius: 3px;
    align-self: center;
    height: 300px;
    margin: 25px;
    background: var(--primary-color);
}

@media screen and (max-width: 768px) {
    .formsContainer {
        flex-direction: column;
    }
    .form {
        width: 100%;
    }
    .formDivider {
        display: block;
        width: 50%;
        border-radius: 3px;
        align-self: center;
        height: 3px;
        margin: 25px;
        background: var(--primary-color);
    }
}
