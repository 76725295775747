.firstbold {
    font-weight: 600 !important;
    font-size: 14px !important;
}

.wrapper {
    position: absolute;
    top: 0;
    left: 20em;
    background-color: rgba(0, 0, 0, 0.43);
    overflow: hidden;
    width: 100vw;
    height: 100vh;
}

.popup {
    padding: calc(2 * var(--gap));
    margin-top: 95px;
    background-color: var(--croonus-white);
    overflow-y: scroll;
    width: 60rem;
    height: 80vh;
    box-shadow: 0 9px 10px -9px var(--border-color);

    &::-webkit-scrollbar {
        display: block;
        width: 3px;
        max-height: 50%;
        height: 50%;
    }

    &::-webkit-scrollbar-track {
        max-height: 50%;
        height: 50%;
    }

    &::-webkit-scrollbar-thumb {
        background-color: var(--croonus-scrollColor);
    }

    &::-webkit-scrollbar-track-piece:start {
        background: transparent;
    }

    a {
        width: fit-content;
        text-transform: uppercase;
        font-size: 1.4rem;
        font-weight: 600;
        cursor: pointer;
        margin: 0;

        &:hover {
            color: var(--primary-color);
        }
    }

    .level2 {
        width: 100%;
        display: grid;
        grid-gap: 2rem;
        padding: calc(2 * var(--gap)) 0 0 var(--gap);
        grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));

        > div {
            h5 {
                font-size: 1rem;
                font-weight: 600;
                color: var(--croonus-black);
                cursor: pointer;

                &:hover {
                    color: var(--primary-color);
                    text-decoration: underline;
                }
            }

            a {
                font-size: 0.8rem;
                font-weight: 400;
                display: block;
                color: var(--croonus-black);
                text-decoration: none;
                padding-top: 0.3em;
                cursor: pointer;

                &:hover {
                    color: var(--primary-color);
                }
            }
        }
    }
}

.a:hover {
    color: var(--primary-color);
}
