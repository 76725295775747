.upit {
    background-color: var(--croonus-black);
    color: white;
    width: 5rem;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem 0.5rem;
    margin-left: auto;
    min-width: 136px;
    cursor: pointer;
}
.tcPrice {
    text-decoration: none;
    color: var(--croonus-black);
}

.discounted {
    color: var(--primary-color);
    text-decoration: none;
}

.rebateStyle {
    color: var(--croonus-black);
    text-decoration: dotted;
    text-underline-position: under;
    text-decoration-line: underline;
}

.discountRabate {
    color: var(--primary-color);
    text-decoration: dotted;
    text-underline-position: under;
    text-decoration-line: underline;
}

.discount {
    color: var(--primary-color);
}
.plusminusinput {
    flex-basis: 100%;
}
.discountBadge {
    display: none;
}

.pricecontainer:hover .discountBadge {
    position: absolute;
    top: -65px;
    left: 20px;
    background-color: var(--primary-color);
    color: var(--croonus-white);
    padding: 0.25rem 0.5rem;
    border-radius: 5px;
    font-size: 0.75rem;
    font-weight: 500;
    transition: all;
    display: block;
    transition-duration: 500ms;
    width: max-content;
    text-align: left;
}

.button1 {
    background-color: var(--croonus-black);
    color: var(--croonus-white);
    padding: 0.5rem 0.5rem;
    border-radius: 5px;
    margin-left: auto;
    height: 40px;
    min-width: 136px;
    cursor: pointer;
}

.button1:hover {
    background-color: var(--croonus-lightRed);
}

.wrapper,
.wrapperMobile {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.25rem 0.5rem;
    margin-bottom: 0.5rem;
    border-radius: 5px;
    background-color: var(--croonus-lightGray);
    transition: color, background-color 340ms;
    color: var(--croonus-black);
    text-decoration: none;
    gap: var(--gap);
    position: relative;
    font-size: 0.9rem;

    > form {
        margin-right: unset;
        display: flex;
        align-items: center;
        background-color: transparent;

        // Allow the "Cart" button to take a bit more space
        div:last-child {
            padding-left: 0;
        }
    }

    &:hover {
        a {
            color: var(--primary-color) !important;
        }
    }

    &:hover {
        //font-weight: 600;
        color: var(--croonus-black) !important;
        background-color: var(--croonus-lightGrayHover);
        outline: 1px solid var(--croonus-lightGrayBorder);
    }
}

.wrapperLink {
    display: flex;
    align-items: center;
    width: 60%;
    text-decoration: none;
    color: var(--croonus-black);
    gap: var(--gap);
    font-size: 0.9rem;

    .sku {
        padding: 0 1rem;
    }
}

.wrapperMobile {
    display: none;
}

.data {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 40%;
    gap: var(--gap);
}

.image {
    height: 40px;
    width: 40px;
}

.sku {
    width: 25%;
    border-right: 1px solid var(--croonus-black);
}

.name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 80%;
    padding-left: var(--gap);
}

.price {
    font-weight: 500;
    width: 40%;
    text-align: right;
}

.numberInput {
    width: 40%;
}

.addToCartButton {
    flex-wrap: wrap;
    overflow: hidden;

    span {
        height: 100%;
        line-height: 2rem;
    }
}

.addToCart {
    width: 50%;
    min-width: 10rem;
    display: flex;
    gap: var(--gap);
}

.imageHover {
    position: absolute;
    left: 45px;
    display: none;
    width: 300px;
    z-index: 5;
}

.image:hover + .imageHover {
    display: block;
}

.imageHover:hover {
    display: block;
}

//RESPONSIVE

@media only screen and (max-width: 1200px) {
    .wrapperBoxMobile {
        flex-direction: column;
        align-items: center;

        .wrapperLink {
            width: 100%;
            justify-content: center;
            .name,
            .sku {
                width: auto;
            }
            .sku {
                padding-right: 1rem;
            }
        }

        .data {
            flex-direction: column;
            width: auto;
            .price {
                width: auto;
                text-align: center;
            }
            .addToCart {
                width: auto;
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    //.data {
    //    flex-direction: column;
    //    width: 60%;
    //    gap: 0;
    //    justify-content: center;
    //}
    //.addToCart {
    //    flex-wrap: wrap;
    //}
    //.name {
    //    width: 100%;
    //    padding: 0;
    //}
    //
    //.price {
    //    width: 100%;
    //    display: flex;
    //    align-items: center;
    //    gap: var(--gap);
    //}
    //.plusMinus {
    //    width: 30%;
    //    height: 100%;
    //}
    //.addToCart {
    //    width: 20%;
    //    height: 100%;
    //}
    //.wrapperMobile {
    //    display: flex;
    //}
    .wrapper.wrapperBoxMobile {
        display: none;
    }
}
