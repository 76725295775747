.tab {
    color: var(--croonus-black);
    border: none;
    background-color: var(--croonus-lightGray);
    border-radius: var(--gap);
    margin: 10px;
}

.hidden {
    display: none;
}

.tabContent {
    padding: var(--gap);
}

.tabTitle {
    font-weight: 600;
    margin-bottom: var(--gap);
}

.tabSection {
    padding-left: var(--gap);
    margin-bottom: var(--gap);
}

.hideTab {
    display: none !important;
}

.sectionTitle {
    font-weight: 500;
}

.specificationRow {
    padding: var(--gap-half);
    margin: 0;
    display: flex;
    font-size: 0.8rem;
    font-weight: 500;
}

.specificationRowTitle {
    width: 25%;
    border-right: 1px solid var(--croonus-black);
}

.specificationRowValue {
    width: 75%;
    padding-left: 5%;
}

.specificationRow:nth-child(even) {
    background-color: var(--croonus-white);
}

.specificationRow:nth-child(odd) {
    background-color: var(--croonus-lightGray);
}

.documentsTable {
    width: 100%;
    table-layout: fixed;
    margin-bottom: 2rem;

    th {
        background-color: var(--croonus-darkGray);
        color: var(--croonus-white);
    }

    th:nth-child(1) {
        border-top-left-radius: var(--gap-half);
        border-bottom-left-radius: var(--gap-half);
    }

    th:last-child {
        border-top-right-radius: var(--gap-half);
        border-bottom-right-radius: var(--gap-half);
    }

    th,
    td {
        word-break: break-word;
        padding: var(--gap-half);
    }
}

.mobileTitle {
    margin: 0;
    font-weight: 800;
}

@media only screen and (max-width: 768px) {
    .tabContent {
        padding: 0;
    }

    .specificationRowTitle {
        width: 40%;
    }

    .specificationRowValue {
        width: 60%;
    }

    .tabSection {
        padding: 0;
    }

    .tabTitle {
        text-align: center;
        font-size: 0.8rem;
    }

    .documentsTable {
        th:nth-child(1) {
            border-radius: 0 !important;
        }

        th:last-child {
            border-radius: 0 !important;
        }

        th,
        td {
            word-break: break-word;
            padding: var(--gap-half);
            text-align: center;
        }
    }
}
