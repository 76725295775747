.modalproduct {
    display: flex;
    gap: 1rem;
    padding: 0.5rem;
    margin-top: 1rem;
    align-items: center;
    border-radius: 8px;
    .quantity {
        width: 15%;
        margin-left: auto;
    }
}
.loadingwidth {
    width: 125px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.notAdded {
    border: 1px solid var(--croonus-lightGray);
}
.added {
    border: 1px solid var(--croonus-lightGreen);
}
.cursor {
    cursor: pointer;
}

.exit {
    position: absolute;
    top: 0;
    right: 0;
    margin: 1rem;
    cursor: pointer;
    font-size: 1.35rem;
}

.wrapper {
    display: grid;
    width: 100%;
    height: 95%;
    grid-template-columns: repeat(3, 1fr);
    gap: var(--gap);
    grid-auto-rows: minmax(100px, auto);

    & > *:nth-child(2) {
        grid-column: 2/5;
    }

    > * {
        min-height: calc(100% - 1.5rem);

        > div > table {
            margin-top: 0;
        }
    }
}

.orderDetails {
    width: 100%;
}

.orderRow {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 4rem;
    margin-bottom: 4rem;
}

.orderItemsData {
    display: flex;
    gap: 2rem;
}

.ordersBlock {
    width: 100%;
}

.ordersBlockTitle {
    text-decoration: underline;
    margin-bottom: 1rem;
    color: var(--croonus-dargGray);
}

.ordersBlockContent {
    padding: 0.5rem 1rem;
    background-color: var(--croonus-lightGray);
    border-radius: 10px;
    height: 100%;

    p {
        margin-bottom: 0.5rem;
        padding: 0;
    }
}

.ordersBlockLabel {
    font-weight: bold;
}

.itemsList {
    width: 100%;
    height: 100%;
    max-height: 50vh;
}

.title {
    text-decoration: underline;
    font-weight: 600;
}

.block {
    font-size: 0.8rem;
}

.duplicate {
    position: relative;
}
.buttonsStyle {
    min-width: 150px;
    display: flex;
    justify-content: center;
    &[disabled]:hover {
        background-color: var(--secondary-color) !important;
        cursor: not-allowed;
    }
    &[disabled] {
        background-color: var(--secondary-color) !important;
    }
}

.tooltipDiv {
    display: inline-block;
}

.printdiv {
    width: 192px;
}

@media print {
    .orderDupPrt {
        display: none !important;
    }
    .wrapper {
        grid-template-columns: 1fr;
        padding: 0 !important;
        & > *:nth-child(2) {
            grid-column: 1/2;
            order: 1;
        }
    }
    .priceBreakdown {
        order: 2;
        width: 40%;
        margin-left: auto;
        margin-top: 1rem;
    }
    .notePrint {
        order: 3;
    }
    .orderInfo {
        display: none;
    }
    .duplicate {
        display: none;
    }
    .tablePrintOverflow tbody {
        overflow: visible !important;
        max-height: none !important;
    }
    .itemsList {
        grid-row-start: 1 !important;
        column-span: all !important;
    }
}
