.container {
    top: 60px;
    height: calc(100vh - 120px);
    height: calc(var(--vh, 1vh) * 100 - 120px);
    display: none;
}
.modal {
    display: none;
    height: 100%;
    width: 100%;
    background-color: var(--croonus-white);
    outline: none;
    padding: 1rem 0.5rem 0;
    overflow: hidden;
}

.modalTitleContainer {
    width: 100%;
    margin-bottom: 1rem;
    display: flex;
}

.modalTitle {
    text-transform: uppercase;
    text-align: center;
    position: relative;
    font-weight: bold;
    width: 100%;
    &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 25%;
        width: 50%;
        height: 110%;
        border-bottom: 3px solid var(--primary-color);
    }
}

.modalContent {
    width: 100%;
    height: 100%;
    overflow: auto;
}

@media screen and (max-width: 768px) {
    .modal {
        display: block;
    }
    .container {
        display: block;
    }
}
