.stickers {
    position: absolute;
    top: 0;
    right: 10%;
    border-radius: 2px solid red;
    z-index: 6;
}

.sticker {
    width: min-content;
    margin-bottom: var(--gap-half);

    img {
        width: 100%;
    }
}

.stickerbg {
    background-color: var(--primary-color);
    width: 100%;
    padding: 0.4rem;
    color: white;
}

.stickerHover {
    pointer-events: none;
    position: absolute;
    top: 0;
    right: 100%;
    transform: translateX(-10%);
    opacity: 0;
    transition: all 0.3s;
    width: 200px;
    padding: var(--gap);
    background-color: var(--croonus-lightGray);
    border-radius: 1rem;
    text-align: center;

    img {
        width: 80%;
    }
}

.sticker:hover .stickerHover {
    display: block;
    transform: translateX(0);
    opacity: 1;
    pointer-events: all;
}