.wrapper {
    height: 100%;
    width: 100%;
    display: flex;
    overflow: hidden;
}

.sidebar {
    height: 100%;
    width: 20rem;
    background-color: var(--croonus-lightGray);
}

.scrollable {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-color: var(--croonus-lightGrayBorder) var(--croonus-lightGray);
}

.content {
    display: block;
    padding: 2rem;
    height: 100%;
    border-radius: 5px;
    width: calc(100% - 20rem);
    min-height: 0;
    overflow: auto;

    time {
        font-size: 13px;
        color: #909090;
        margin-bottom: 0.4rem;
        display: flex;
    }
    a {
        color: white;
        text-decoration: none;
        background: var(--primary-color);
        padding: 0.4rem 1rem;
        border-radius: 0.5rem;
        margin-top: 1rem;
        display: inline-block;
        width: fit-content;
        &:hover {
            background: var(--primary-color-hover);
        }
    }
}

@media screen and (max-width: 768px) {
    .wrapper {
        flex-direction: column;
    }
    .sidebar {
        width: 100%;
        height: auto;
    }
    .content {
        width: 100%;
    }
}
