.wrapper {
    width: 90%;
    display: flex;
    justify-content: center;
    padding: 2em;
}

.wrapper.nopadding {
    padding: 0;
    position: absolute;
    left: 38%;
}
