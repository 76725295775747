.grid {
    width: 100%;
    display: grid;
    grid-gap: 2rem;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));

    // Not really working
    &.single {
        grid-template-rows: max-content 0 0 0 0 0 0 0 0 0 0 0 0;
        overflow: hidden;
    }

    // Not really working
    &.double {
        grid-template-rows: max-content max-content 0 0 0 0 0 0 0 0 0 0 0 0;
    }
}

.list {
    width: 100%;
}

@media screen and (max-width: 768px) {
    .grid {
        grid-gap: 1rem;
        grid-template-columns: repeat(2, calc(50% - 0.5rem));
    }
}
