.wrapper {
    height: 100%;
    overflow: auto;
    width: calc(100% - 70px);

    @media only screen and (max-width: 768px) {
        & {
            width: 100%;
            height: 100%;
        }
    }
}

@media print {
    .wrapper {
        width: 100%;
    }
}
