.title {
    font-size: 5rem;
    font-weight: 600;
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
