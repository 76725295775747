.wrapper {
	margin-top: var(--gap);

	> .tabs {
		display:         flex;
		justify-content: center;
		gap:             var(--gap);
		list-style:      none;
		padding:         0;
		margin:          var(--gap) 0;

		> * {
			padding:       var(--gap-quarter) var(--gap);
			color:         var(--croonus-black);
			cursor:        pointer;
			border-radius: 5px;

			&.active {
				color:            var(--croonus-white);
				background-color: var(--croonus-black);
			}
		}
	}

	> .content {
	}

}
