.pagination-container {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--croonus-lightGray);
    padding: 0.25rem 3rem;
    border-radius: 5px;
    margin-top: 1rem;
    justify-content: space-between;
    font-size: 0.8rem;
}
.pagination-button {
    border: none;
    text-align: center;
    padding: 0.25rem 0.5rem;
    font-weight: 500;
    border-radius: 5px;
    width: auto;
    margin: 0 0.25rem;
    background-color: var(--croonus-white-darker);
}
.pagination-navigate {
    border: none;
    text-align: center;
    border-radius: 5px;
    width: auto;
    margin: 0 0.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--croonus-white-darker);
}
.pagination-button:hover {
    background-color: var(--primary-color);
    color: var(--croonus-white);
}
.pagination-button-selected {
    border: none;
    background-color: var(--primary-color);
    color: var(--croonus-white);
    font-weight: 600;
    font-size: 1rem;
    border-radius: 5px;
    text-align: center;
    padding: 0.25rem 0.5rem;
    width: auto;
    margin: 0 0.25rem;
}
.pagination-items-count {
    padding: unset;
    margin: unset;
    width: auto;
}
.pagination-number-container {
    width: auto;
    text-align: center;
    display: flex;
}

@media (max-width: 991.98px) {
    .pagination-container {
        padding: 1rem 2rem;
        flex-direction: column;
        justify-content: center;
    }
    .pagination-items-count {
        padding-bottom: 0.5rem;
    }
}

@media (max-width: 768px) {
    .pagination-container {
        margin-right: 0;
    }
}
