.wrapper {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50px;
    list-style: none;
    top: 25%;
    right: 1.5rem;
    padding: 0;

    > li {
        position: relative;

        span {
            display: block;
            padding: 0.5rem;
            border-radius: 5px;
            border: none;
            background-color: var(--primary-color);
            margin-bottom: 0.25rem;

            &:hover {
                background-color: var(--primary-color-hover);

                & img {
                    filter: brightness(0) invert(1);
                }
            }

            img {
                width: 100%;
                filter: brightness(0) invert(1);
            }
        }

        &:hover > div {
            opacity: 1;
            transform: translateX(-110%);
        }
        > div {
            position: absolute;
            width: 200px;
            padding: 0.25rem 1rem;
            opacity: 0;
            line-height: 1.25rem;
            height: 50px;
            pointer-events: none;
            border-radius: 5px;
            transform: translateX(-100%);
            transition: transform 0.5s, opacity 0.5s;
            top: 0;
            min-width: 15rem;
            background-color: var(--primary-color-hover);
            color: var(--croonus-white);
        }
    }
}

@media screen and (max-width: 768px) {
    .wrapper {
        position: static;
        width: 100%;
        flex-direction: row;
        margin-top: 2rem;
        margin-bottom: 0;
        gap: var(--gap-half);
        height: 50px;
        > li {
            height: 100%;
            span {
                height: 100%;
                img {
                    width: auto;
                    height: 100%;
                }
            }
            > div {
                transform: translate(-10%, 110%);
                z-index: 3;
            }

            &:hover > div {
                opacity: 1;
                transform: translate(0, 110%);
            }
        }
    }
}
