.container {
    width: 100%;
    height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.button {
    width: 100%;
    padding: var(--gap);
    background-color: var(--croonus-black);
    border: none;
    border-radius: 0;
    box-sizing: border-box;
    text-align: center;
}
