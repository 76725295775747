.form {
    position: relative;
    height: 60%;
    z-index: 120;

    input {
        width: 17rem;
        height: 100%;
        padding: 0 1rem;
        border-radius: 8px;
        border: 1px solid rgb(224, 223, 223);
    }
}

.results {
    position: absolute;
    width: 100%;
    font-size: 0.9rem;
    z-index: 120;
    background-color: white;
    box-shadow: -1px 11px 15px -2px;
}

.result:hover {
    background-color: #e6dada;
    color: white !important;
}

.moreresults {
    position: sticky;
    bottom: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: black;
    color: white !important;
    text-align: center;
    padding: 0.5rem 0;
}

.result {
    display: flex;
    gap: 1.1rem;
    align-items: center;
    padding: 0.5rem 1rem;
    color: black !important;
}

.focused {
    background-color: #e6dada;
}

.button {
    height: 100%;
    margin-left: 0.5rem;
    padding: 0 1.5rem;
    background-color: var(--primary-color);
    border: none;
    border-radius: 8px;

    img {
        height: 60%;
        filter: brightness(0) invert(1);
    }
}

.button:hover {
    background-color: var(--primary-color-hover);
}

@media screen and (max-width: 768px) {
    .form {
        display: none;
    }
}
