@import "variables";
@import "~bootstrap/scss/bootstrap.scss";
@import "~react-toastify/dist/ReactToastify.css";
@import "montserrat-font.scss";
@import "toastify";
@import "utilities";
@import "input-range";

body {
    font-family: "Montserrat", "sans-serif" !important;
    background-color: var(--croonus-white);
    margin: 0;
    overflow: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: var(--text-color);
}

input {
    border: none;
}

input:focus {
    outline: none;
}

.main-card .main-card {
    padding: 0 !important;
    min-width: auto;
    min-height: auto;
}

a {
    color: var(--primary-color);

    &:hover {
        color: var(--primary-color-hover);
    }
}

.form-check-input:checked {
    background-color: var(--primary-color);
    border-color: var(--primary-color-hover);
}

h2 {
    position: relative;
    width: fit-content;
    margin-bottom: 2.3rem;
}

.Toastify__toast-body {
    font-family: "Montserrat", "sans-serif" !important;
    font-size: 1rem !important;
}

/*----------------- DESCRIPTION LISTS ------------------*/
dl {
    > * {
        margin-bottom: var(--gap-half);
    }

    dt {
        float: left;
        clear: both;
        margin-right: 1ex;
        &:after {
            content: ":";
        }
    }

    dd {
        float: left;
    }

    &.right {
        dd {
            float: right;
        }
    }
}

/*---------------- COMMON STYLES START -----------------*/

.green-text {
    color: #008548;
}

.red-text {
    color: red;
}

.black-text {
    color: var(--croonus-black);
}

.bg-red {
    background-color: var(--croonus-darkRed);
}

.bg-green {
    background-color: var(--croonus-darkGreen);
}

.bg-blue {
    background-color: var(--croonus-darkBlue);
}

.btn-basic {
    outline: none;
    border: none;
    padding: 0.3125rem 1.5rem;
    border-radius: 5px;
}

.btn-basic.btn-basic-primary {
    background-color: var(--primary-color);
    color: var(--croonus-white);
}

.btn-basic.btn-basic-primary:hover {
    background-color: var(--primary-color-hover);
    color: var(--croonus-white);
}

.btn-basic.btn-basic-secondary {
    background-color: var(--croonus-black);
    color: var(--croonus-white);
}

.btn-basic.btn-basic-secondary:hover {
    background-color: var(--primary-color);
    color: var(--croonus-white);
}

.btn-image {
    max-width: 100%;
    max-height: 100%;
}

.half-underline {
    position: relative;
    width: fit-content;
    font-size: 2rem;
}

.half-underline:after {
    content: "";
    height: 3px;
    width: 70%;
    background: var(--primary-color);
    position: absolute;
    bottom: -10px;
    right: -14px;
}

.white-bg-border-radius {
    padding: 0.25rem 1rem;
    background-color: var(--croonus-white);
    border-radius: 5px;
}

.login-form-style .error-text {
    padding: 0 0.75rem;
    color: var(--croonus-alertRed);
}

.relative {
    position: relative;
}

.flex-row {
    display: flex;
    align-items: center;
}

.flex-column {
    display: flex;
    flex-direction: column;
    align-items: center;
}

/*---------------- COMMON STYLES END -------------------*/

/*---------------- CART PREVIEW MODAL STYLES START -------------------*/
.card-preview-modal {
    .modal-dialog {
        margin-top: unset;
        margin-bottom: unset;
        height: 100%;
        padding: 2rem 0;
    }

    .modal-content {
        height: 100%;
        border: unset;
        border-radius: unset;
    }

    .modal-header {
        padding: 2rem 3rem;
    }

    .modal-header,
    .modal-footer {
        border: unset;
        border-radius: unset;
    }

    .modal-footer {
        display: flex;
        justify-content: center;
        padding-bottom: 2.5rem;

        .btn-basic {
            margin: 0 1.5rem;
        }
    }

    .modal-body {
        padding: 1rem 1.5rem;
        font-size: 0.75rem;
    }
}

.shipping-modal-restyle {
    .modal-header {
        padding: 1rem 1.5rem;
    }

    .modal-body {
        padding: 1rem 1.5rem;

        input {
            width: 100%;
            outline: none;
            border: none;
            font-size: 1.2rem;
            padding: 0.5rem 1rem;
            border-radius: 10px;
            background-color: var(--croonus-lightGray);
            box-shadow: unset;
        }

        label {
            padding: 0.5rem;
        }

        .input-padding {
            padding: 0 0.5rem;
        }

        .error-text {
            padding-left: 0.5rem;
        }
    }

    .modal-header,
    .modal-footer {
        border: unset;
        border-radius: unset;
    }

    .modal-footer {
        display: flex;
        justify-content: center;
        padding-top: 1.75rem;
        padding-bottom: 2.5rem;

        .btn-basic {
            margin: 0 1.5rem;
        }
    }
}

// LightBox thumbnails
.css-1o906pp {
    background-size: contain !important;
}

/*---------------- CART PREVIEW MODAL STYLES END -------------------*/

/*--------------------- CSS FOR PAGES ----------------------*/
.category-page-content {
    padding-left: 20em;
    width: 100%;
}

.single-product-accordion {
    .card-body {
        padding: 1rem 2rem;
    }
}

/*-------------------- CSS FOR PAGES END -------------------*/
/*-------------------- SPINNER START -------------------*/
.spinner-container {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 10000;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.23) no-repeat 0 0;
}

.triple-spinner {
    display: block;
    position: relative;
    width: 125px;
    height: 125px;
    border-radius: 50%;
    border: 4px solid transparent;
    border-top: 4px solid var(--primary-color);
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
}

.triple-spinner::before,
.triple-spinner::after {
    content: "";
    position: absolute;
    border-radius: 50%;
    border: 4px solid transparent;
}

.triple-spinner::before {
    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 5px;
    border-top-color: var(--secondary-color);
    -webkit-animation: spin 3s linear infinite;
    animation: spin 3.5s linear infinite;
}

.triple-spinner::after {
    top: 15px;
    left: 15px;
    right: 15px;
    bottom: 15px;
    border-top-color: var(--croonus-scrollColor);
    -webkit-animation: spin 1.5s linear infinite;
    animation: spin 1.75s linear infinite;
}

@-webkit-keyframes spin {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes spin {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

/*--------------------- SPINNER END ----------------------*/
/*--------------------- RESPONSIVE START ----------------------*/
@media (max-width: 1199.98px) {
    .category-page-content {
        padding-left: unset;
        width: 100%;
    }
}

/*-------------------- RESPONSIVE END -------------------*/
