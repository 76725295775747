.form {
    width: 40%;
    input {
        width: 100%;
        margin-bottom: 0.8rem;
        padding: 0.44rem 1rem;
        border-radius: 8px;
        border: 1px solid rgb(224, 223, 223);
    }
}

.button {
    margin-left: 0.5rem;
    padding: 0.44rem 1.6rem;
    background-color: var(--primary-color);
    border: none;
    border-radius: 8px;

    img {
        width: 22px;
        filter: brightness(0) invert(1);
    }
}

.button:hover {
    background-color: var(--primary-color-hover);
}

@media screen and (max-width: 768px) {
    .form {
        display: none;
    }
}
