.container {
    height: 95vh;
    width: 100%;
    padding: var(--gap);
    overflow: auto;
}
.cartTableHolder {
    overflow-y: scroll;
    height: 70vh;
    thead {
        position: sticky;
        top: 0;
        background-color: white;
        z-index: 1;
    }
}
//.content {
//    height: 70vh;
//    overflow-y: scroll;
//}

.title {
    margin-bottom: 2rem;
}

.continue {
    background-color: var(--primary-color);
    color: var(--croonus-white);
}

.checkout {
    background-color: var(--croonus-black);
    color: var(--croonus-white);
}

.emptyPage {
    width: 100%;
    padding: 0 !important;
    height: 90%;
}

.overflow {
    overflow: visible;
    height: 90%;
}
