.link {
    height: 70%;
    width: 40px;
    background-color: transparent;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3px;
    border-radius: 3px;
    border: none;
    img {
        width: 100%;
        filter: brightness(0) invert(1);
    }
}

.link:focus {
    outline: none;
}

.link:hover {
    background-color: #333333;
}

.alert {
    width: 21px;
    height: 21px;
    background-color: var(--primary-color);
    border-radius: 100%;
    position: absolute;
    right: -7px;
    top: -4px;
    color: var(--croonus-white);
    z-index: 3;
    font-size: 0.9em;
    font-weight: 600;
    line-height: 21px;
    text-align: center;
    border: 0.1px solid var(--border-color);
    text-align: center;
}

@media screen and (max-width: 768px) {
    .link {
        img {
            height: 90%;
            width: auto;
        }
    }
}
