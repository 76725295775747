.loading {
    position: absolute;
    width: 100%;
    padding: 0.3rem;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.7);
    z-index: 100;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.table {
    font-size: 0.8rem;
}

.tcPrice2 {
    text-align: right;
}

.tcActions {
    text-align: right;
    cursor: "pointer";
}

.tcInt {
    text-align: center;
}

.plusminus {
    width: 100%;
}

.plusminus1 {
    width: calc(100% - 30px);
    margin: auto;
    margin: 0 15px;
}

.rebate1 {
    text-align: right;
}

.rebate2,
.rebate {
    text-align: center;
}

.tcPrice {
    text-align: right;
    text-decoration: none;
}

.discounted {
    color: var(--primary-color);
    text-decoration: none;
}

.rebateStyle {
    text-decoration: dotted;
    text-underline-position: under;
    text-decoration-line: underline;
}

.discountRabate {
    text-decoration: dotted;
    text-underline-position: under;
    text-decoration-line: underline;
    color: var(--primary-color);
}

td,
th {
    padding: 0 5px;
}

.tcTotalLabel,
.tcTotalPrice {
    text-align: right;
    font-weight: 700;
    padding-top: 2rem;
}

.tbody {
    border-collapse: separate;
    border-color: transparent !important;
    border-style: solid !important;
    border-width: 2px !important;
}

//.table {
//    width: 100%;
//    margin-top: 1rem;
//    border-top: none;
//    height: 100%;
//    font-size: 0.8rem;
//
//    > thead {
//        > tr {
//            width: 100%;
//            border-radius: 0.5rem;
//            padding: 0.4rem 2rem;
//            justify-content: space-around;
//
//            > th:not(:last-child)::before {
//                content: "";
//                height: 15px;
//                width: 1px;
//                background-color: white;
//
//                display: flex;
//                top: 0.8rem;
//                left: 0rem;
//                position: absolute;
//            }
//
//            > th:first-child::before {
//                content: none;
//            }
//
//            > th {
//                position: relative;
//            }
//
//            > th:first-child {
//                border-top-left-radius: 0.5rem;
//                border-bottom-left-radius: 0.5rem;
//            }
//
//            > th:last-child {
//                border-top-right-radius: 0.5rem;
//                border-bottom-right-radius: 0.5rem;
//            }
//        }
//    }
//
//    > tbody {
//        display: block;
//        width: 100%;
//        overflow: auto;
//        table-layout: fixed;
//
//        > tr {
//            display: table;
//            line-height: 1rem;
//            // Zebra colors, with hover
//            background-color: var(--table-row-odd);
//
//            &:hover {
//                cursor: pointer;
//            }
//
//            &:nth-child(even) {
//                background-color: #f8f8fa;
//            }
//        }
//    }
//
//    thead,
//    tbody tr,
//    tr {
//        display: table;
//        width: 100%;
//        table-layout: fixed;
//    }
//
//    thead {
//    }
//
//    th {
//        font-weight: 600;
//    }
//}
//
//// Applies to both headers and cells
//.table > * > * > * {
//    text-align: left;
//    padding: 0 0;
//
//    // Text alignment
//    &:global.left {
//        text-align: left !important;
//    }
//
//    &:global.center {
//        text-align: center !important;
//    }
//
//    &:global.right {
//        text-align: right !important;
//    }
//
//    @media only screen and (max-width: 768px) {
//        &:global.mobile-hidden {
//            display: none;
//        }
//    }
//}
//
//.tcImage {
//
//    img {
//        max-width: 80%;
//        aspect-ratio: 230/150;
//    }
//}
//
//
//.productName {
//    max-height: 1rem;
//    overflow: hidden;
//    margin: 0;
//}
//
//
//.tcActions {
//
//    text-align: end;
//
//    i {
//        font-size: 1.3rem;
//        cursor: pointer;
//        transition: color 240ms;
//        color: var(--croonus-inactive);
//
//        &:hover {
//            color: var(--croonus-alertRed);
//        }
//    }
//}
