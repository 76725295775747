.top-margin-1 {
    margin-top: 1rem;
}
.product-details-slider-container .row {
    justify-content: space-around;
}
.carousel-main {
    button:focus {
        box-shadow: none;
    }
    .carousel-cell:hover {
        cursor: pointer;
    }
    counter-reset: carousel-cell;
    .carousel-cell {
        width: 100%;
        height: 100%;
        font-size: 5rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        counter-increment: carousel-cell;
        img {
            max-width: 100%;
            max-height: 100%;
            height: auto;
            width: auto;
            // content: counter(carousel-cell);
        }
    }
    .flickity-viewport {
        height: 350px !important;
    }
    .flickity-page-dot.is-selected {
        background-color: var(--croonus-lightGrayHover);
    }
    .flickity-page-dot.is-selected {
        background-color: var(--primary-color);
    }
}

.carousel-nav {
    width: 300px;
    height: 80px;
    transform: rotate(90deg) translate(20px, -120%);
    transform-origin: left top;
    button:focus {
        box-shadow: none;
    }
    @media screen and (max-width: 1200px) {
        transform: rotate(90deg) translate(10px, -100%);
    }
    .flickity-viewport {
        height: 80px !important;
    }
    .carousel-cell-1 {
        transform: rotate(-90deg);
        width: 80px;
        height: 80px;
        cursor: pointer;
        font-size: 1.4rem;
        position: absolute;
        display: flex;
        align-content: center;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        counter-increment: carousel-cell;
        img {
            transform: rotate(270deg);
            height: auto;
            width: 100%;
            padding: 0;
            max-height: 80%;
            object-fit: contain;
            // content: counter(carousel-cell);
        }
        /* selected cell */
        &.is-nav-selected {
            border: 1px solid var(--border-color);
        }
    }
    .carousel-cell-1:hover {
        border: 1px solid var(--border-color);
    }

    .flickity-prev-next-button {
        width: 40px;
        height: 40px;
        background: transparent;

        &.previous {
            left: -40px;
        }
        &.next {
            right: -40px;
        }
    }
}
.main-details-under-primary-image {
    height: 100px;
    display: flex;
    align-items: center;
}
.main-details-under-primary-image-button {
    object-fit: contain;
    border: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: transparent;
    span {
        font-size: 0.8rem;
        white-space: nowrap;
    }
    img {
        width: 1.75rem;
        height: auto;
    }
    .heart-wrapper {
        border-radius: 50%;
        background-color: var(--croonus-lightGray);
    }
}
.main-details-under-primary-image-button.active,
.main-details-under-primary-image-button:hover {
    .heart-wrapper {
        background-color: var(--primary-color);
    }
    img {
        filter: brightness(0) invert(1);
    }
}
.main-details-under-primary-image-button:hover {
    box-shadow: 0 10px 5px -10px var(--border-color);
}
