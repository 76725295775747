.wrapper {
    padding: 1rem 1rem 1rem 21rem;
    width: 100%;

    @media (max-width: 768px) {
        & {
            padding: 1rem;
        }
    }
}
